import { render, staticRenderFns } from "./ContentSection.vue?vue&type=template&id=187b0ceb&scoped=true"
import script from "./ContentSection.vue?vue&type=script&lang=ts"
export * from "./ContentSection.vue?vue&type=script&lang=ts"
import style0 from "./ContentSection.vue?vue&type=style&index=0&id=187b0ceb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187b0ceb",
  null
  
)

export default component.exports