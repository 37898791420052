import { render, staticRenderFns } from "./ButtonGroup.vue?vue&type=template&id=35ee061a&scoped=true"
import script from "./ButtonGroup.vue?vue&type=script&lang=ts"
export * from "./ButtonGroup.vue?vue&type=script&lang=ts"
import style1 from "./ButtonGroup.vue?vue&type=style&index=1&id=35ee061a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ee061a",
  null
  
)

export default component.exports